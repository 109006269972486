<template>
  <form @submit.prevent="onSubmit()" name="accountEdit">
    <div class="view view--user">
      <div class="view-header" v-if="pageTitle">
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            :to="{ name: 'myAccount' }"
            round
            grow
            icon="arrow-left"
          />
          <h1 class="page-title" v-html="pageTitle"></h1>
        </div>
      </div>

      <div class="view-body">
        <Section class="section--styleguide">
          <Container>
            <div class="grid">
              <div class="tmp-col">
                <InputImage
                  id="avatar"
                  type="avatar"
                  :avatarImage="data.avatar ?? null"
                  :avatarInitials="
                    helperService.userInitials(data.prenom, data.nom)
                  "
                  :avatarColor="data.couleur"
                  avatarSize="large"
                  label="Photo de profil"
                  textInfo="Fichier au format JPEG et PNG, 1Mo maximum"
                  @update:modelValue="setMedia($event)"
                />
              </div>

              <div class="tmp-col col-order-first">
                <KeyValue label="Prénom" :value="data.prenom" />
                <KeyValue label="Nom" :value="data.nom" />
                <KeyValue label="Identifiant" :value="data.identifiant" />
                <Input type="email" id="email" label="Email" required />
                <Input type="tel" id="telportable" label="Téléphone" />
              </div>
            </div>
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn :to="{ name: 'myAccount' }" text="Annuler" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import KeyValue from '@/components/form/KeyValue.vue'
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import InputImage from '@/components/form/InputImage.vue'

import * as yup from 'yup'

export default {
  name: 'MyAccountEditView',

  components: {
    InputImage,
    KeyValue,
    Input,
    Btn,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = yup.object().shape({
      email: yup.string().required().email(),
      telportable: yup.string().nullable(),
      avatar: yup.object().nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      data: {},
      onSubmit,
      errors,
      isSubmitting,
    }
  },
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    setMedia(event) {
      this.data.avatar = event
    },
    handleValues(values) {
      this.emitter.emit('open-loader')
      // eslint-disable-next-line no-param-reassign
      values.nom = this.$store.state.auth.user.data.nom
      // eslint-disable-next-line no-param-reassign
      values.prenom = this.$store.state.auth.user.data.prenom
      // eslint-disable-next-line no-param-reassign
      values.avatar_id = values.avatar?.id || this.data?.avatar?.id
      // eslint-disable-next-line no-param-reassign
      values.user_id = this.$store.state.auth.user.data.id
      // eslint-disable-next-line no-param-reassign
      delete values.avatar
      this.$store.dispatch('auth/updateUser', values).then(
        () => {
          this.$router.push({ name: 'myAccount' })
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.formService.handleApiError(responseError)
          this.emitter.emit('close-loader')
        },
      )
    },
    getData() {
      this.fetchService
        .get(`utilisateur/${this.$store.state.auth.user.data.id}`, {})
        .then(
          (response) => {
            this.data = response.data

            // Récupération du schéma définie avec les valeurs renseignées
            this.schema = this.formService.populateShema(this.data)

            // Ajout de valeur supplémentaire ou réaffectation de data
            this.schema.entite_id = this.data.entite.id

            this.formService.setFormValues(this.schema)
          },
          (responseError) => {
            console.log('fetchError', responseError)
          },
        )
    },
  },
}
</script>
